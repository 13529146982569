// Variable overrides
/*! `Fresca` Bootstrap 4 theme */
/* quicksand-300 - latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('./fonts/quicksand/quicksand-v22-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/quicksand/quicksand-v22-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* quicksand-regular - latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('./fonts/quicksand/quicksand-v22-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/quicksand/quicksand-v22-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* quicksand-700 - latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('./fonts/quicksand/quicksand-v22-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/quicksand/quicksand-v22-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

$font-family-base:Quicksand;
$headings-font-family:Quicksand;

// $enable-grid-classes:false;
$primary:#07689F;
$secondary:#C9D6DF;
$success:#11D3BC;
$danger:#F67280;
$info:#A2D5F2;
$warning:#FF7E67;
$light:#FAFAFA;
$dark:#4e4e4e;

// Add SASS theme customizations here..
